import React from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {Link} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
    footer: {
        flex: 0,
        textAlign: "center",
        padding: theme.spacing(2),
    },
    link: {
        padding: theme.spacing(0, 0.5),
    },
    temp: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function Footer() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container className={classes.footer} component="footer">
                <Box>
                    <Typography variant="caption">
                        注意：如需查询 
                         <Typography variant="caption" color="secondary">
                            微博UID
                        </Typography>
                        ，请在输入的UID前面加 
                        <Typography variant="caption" color="secondary">
                            @
                        </Typography>。
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="caption">
                        　
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="caption">
                        喵~
                    </Typography>
                </Box>
				<Box>
                    <Typography display="inline">.</Typography>
					<Link color="secondary" href="https://library.aiuys.com/?_=bkA" target="_blank"
                          className={classes.link}>地址 A</Link>
                    <Typography display="inline">.</Typography>
					<Link color="secondary" href="https://detail.aiuys.com/?_=bkB" target="_blank"
                          className={classes.link}>地址 B</Link>
                    <Typography display="inline">.</Typography>
					<Link color="secondary" href="https://privacy.aiuys.com/?_=bkC" target="_blank"
                          className={classes.link}>地址 C</Link>
                    <Typography display="inline">.</Typography>
                </Box>
				<Box mt={2} className={classes.temp}>
                    <Typography display="inline">Retrofit with</Typography>
                    <FavoriteIcon color="error" fontSize="default" className={classes.link}/>
                    <Typography display="inline">by</Typography>
                    <Link color="secondary" href="javascript:;"
                          className={classes.link}>aiuys.</Link>
                    <Typography display="inline"></Typography>
                </Box>
                <Box mt={2} className={classes.temp}>
                    <Typography display="inline">Developed with</Typography>
                    <FavoriteIcon color="error" fontSize="default" className={classes.link}/>
                    <Typography display="inline">by</Typography>
                    <Link color="secondary" href="https://github.com/kallydev" target="_blank"
                          className={classes.link}>KallyDev</Link>
                    <Typography display="inline">.</Typography>
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default Footer;
